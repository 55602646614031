function AssessResponseScore(props) {

  // Define variables
  const title = props.title;
  const score = props.score;


  // Screen components
  let scoreElement;
  let feedbackElement;

  if( score.label ) {
    scoreElement = (
      <p class='assess-score-heading'>
        {/* <span>{title}</span> {score.label} */}
        <span>{title}</span>
        <span class='assess-score-sub-score'>{score.score}<small style={{color: '#6D6D6D'}}> / 100</small> </span>
      </p>
    );
  }

  if( score.feedback ) {
    feedbackElement = <p><i>{score.feedback}</i></p>;
  }

  return (
    <div>
      {scoreElement}
      {feedbackElement}
    </div>
  )
}

export default AssessResponseScore;