import { useState } from "react";
import CourseLessons from './courseLessons';


function Course(props) {

  // Define variables
  const [showList, setShowList] = useState(false);
  const course = props.course;


  // Screen components
  let lessonsElement;
  if( showList ) {
    lessonsElement = <CourseLessons lessons={course.lessons} />;
  }

  return(
    <div class='course-card'>
      <h4>{course.title}</h4>
      <p class='p-secondary'>{course.level} | {course.duration} | {course.lessonCount} lessons</p>

      <div class='flex-row' style={{alignItems: 'flex-start'}}>
        <p>{course.description}</p>
        <button
          type='button'
          class='flex-row'
          onClick={() => setShowList(!showList)}
          title='Click to show/hide the lessons list'
          style={{flexShrink: 0, marginLeft: '30px'}}
        >
          <p class='p-secondary small' style={{marginBottom: 'initial', marginRight: '5px'}}>{showList ? 'Hide' : 'Show'} Lessons</p>
          <span class={`icon ${showList ? 'icon-chevron-up' : 'icon-chevron-down'}`} style={{width: '12px', opacity: '.8'}}></span>
        </button>
      </div>

      {lessonsElement}

    </div>
  );
}

export default Course;