function TdRemoveInvite(props) {

  // Define variables
  const id = props.id;
  const removeInviteFunction = props.removeInviteFunction;


  return(
    <td class='td-icon'>
      <button
        onClick={() => removeInviteFunction(id)}
        class='icon icon-cancel-fill'
        title='Remove seat'
      />
    </td>
  );
}

export default TdRemoveInvite;