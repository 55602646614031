import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../utilities/header';
import Footer from '../utilities/footer';
import OrganizationCard from '../utilities/organizationCard';

import ResourceQuickstartClass from './resourceQuickstartClass';
import ResourceQuickstartTeam from './resourceQuickstartTeam';
import ResourceAddFlowToPackage from './resourceAddFlowToPackage';
import CoursesList from './coursesList';
import PathwaysList from './pathwaysList';


function ResourceScreen() {

  const { document } = useParams();

  let documentElement;
  if( document === 'quick-start-using-flow-with-your-class') {
    documentElement = <ResourceQuickstartClass />;
  } else if( document === 'quick-start-using-flow-with-your-team') {
    documentElement = <ResourceQuickstartTeam />;
  } else if( document === 'guide-add-flow-to-your-learning-package') {
    documentElement = <ResourceAddFlowToPackage />;
  } else if( document === 'courses') {
    documentElement = <CoursesList />;
  } else if( document === 'pathways') {
    documentElement = <PathwaysList />;
  }


  // Component load actions
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div class='flex-column'>
      <Header />
      <OrganizationCard />
      {documentElement}
      <Footer />
    </div>
  );
}

export default ResourceScreen;