import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import { isAuthed, saveToken } from '../utilities/auth/tokenHelpers';
import apiCall from '../../helperFunctions/apiCall';
import { register } from '../utilities/auth/authAPI';
import Header from '../utilities/header';
import ButtonPrimary from '../utilities/buttonPrimary';
import InputPassword from '../utilities/forms/inputPassword';


function RegisterScreen() {

  // Define variables
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buttonLoading, setButtonLoading] = useState('');


  // Component load actions
  useEffect(() => {

    const loggedIn = isAuthed();
    if( loggedIn ) {
      navigate('/');
    }

  }, []);


  // Define functions
  function handleSubmit(event) {
    event.preventDefault();

    if( !firstName || !email || !password ) {
      toast.error('Please enter your first name, email, and password');
      return;
    }

    setButtonLoading(true);

    const payload = {
      user: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
      },
      orgAdmin: true
    };

    apiCall(register, payload).then((response) => {
      if( response.success ) {
        saveToken(response.data.jwt);
        setTimeout(() => navigate('/onboard'), 400);
      } else {
        let duration = 5000;
        if( response.error.length > 50 ) { duration = 15000 }
        toast.error(response.error, {duration: duration});
      }
    }).finally(() => {
      setTimeout(() => setButtonLoading(null), 400);
    });
  }


  return(
    <div class='flex-row start-wrapper'>

      <div class='start-left-column'>
        <div class='start-left-inner'></div>
      </div>

      <div class='start-right-column'>
        <div class='flex-column start-right-inner'>

          <Header />

          <form onSubmit={handleSubmit}>

            <h2 class='outfit'>Create Your Account</h2>
            <p class='p-secondary' style={{marginTop: '10px', marginBottom: '30px', maxWidth: '450px'}}>Create your FLOW Administrator account to use FLOW Speak and the FLOW Assessment with your organization.</p>

            <div class='field'>
              <label>First name</label>
              <input
                name="firstName"
                type="text"
                value={firstName}
                onChange={event => setFirstName(event.target.value)}
                required
                placeholder='First name'
                title="Enter your first name"
              />
            </div>

            <div class='field'>
              <label style={{marginTop: '20px'}}>Last name</label>
              <input
                name="lastName"
                type="text"
                value={lastName}
                onChange={event => setLastName(event.target.value)}
                placeholder='Last name (optional)'
                title="Enter your last name"
              />
            </div>

            <div class='field'>
              <label style={{marginTop: '20px'}}>Email address</label>
              <input
                name="email"
                type="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                required
                placeholder='Email address'
                title="Enter your email address"
              />
            </div>

            <div class='field'>
              <label style={{marginTop: '20px'}}>Password</label>
              <InputPassword
                value={password}
                handleOnChange={setPassword}
              />
            </div>

            <div style={{marginTop: '60px'}}>
              <ButtonPrimary
                value={'Register'}
                loading={buttonLoading}
                buttonClass={'button tertiary'}
              />

              <div class='start-link-container' style={{marginTop: '20px'}}>
                <p>
                  By clicking Continue, you agree to the <br /><a href='https://www.flowspeak.io/terms-and-conditions' target='_blank' rel="noreferrer">Terms & Conditions</a> and <a href='https://www.flowspeak.io/privacy-policy' target='_blank' rel="noreferrer">Privacy Policy</a>
                </p>
              </div>

            </div>


            <div class='start-link-container'>
              <p>Already have an account?</p>
              <p>
                <a href='/login'>Login to your FLOW Administrator account</a>
              </p>
            </div>

          </form>

          {/* <div style={{height: '80px', width: '1px'}}></div> */}

        </div>
      </div>
    </div>
  );
}

export default RegisterScreen;