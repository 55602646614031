import { Link } from "react-router-dom";


function ResourceAddFlowToPackage() {

  return (
    <div class='body-container resource'>
      <h2>Guide: Adding FLOW Speak to Your Learning Package</h2>

      <p>
        Congratulations on creating your FLOW Administrator account! This guide will cover why and how you can include FLOW Speak in your learning package.
      </p>

      <p>&nbsp;</p>

      {/* ------------------------ */}


      <h2>Benefits of offering FLOW Speak to your students</h2>
      <p>
        Adding FLOW Speak to your learning package has benefits for both you and your students.
      </p>


      {/* ------------------------ */}


      <h3>Student Benefits</h3>
      <p>
        <strong>More speaking practice</strong>
        <br />
        The more speaking practice your students get, the faster they will improve. Class time is limited and it's often hard to work in a lot of student speaking time. Students who use FLOW Speak in combination with your personalized teaching can vastly increase the amount of time they get to practice speaking English.
      </p>

      <p>
        <strong>Instant, detailed feedback</strong>
        <br />
        You rarely get to spend much one on one time with each student to give them detailed feedback about their English speaking skills. FLOW Speak's AI powered feedback gives instant feedback that is very specific for pronunciation, relevance, grammar, vocabulary, and fluency.
      </p>

      <p>
        <strong>Safe space</strong>
        <br />
        As teachers, we appreciate our outspoken students who eagerly participate—but they often make up only 10-15% of the class. The rest may feel too intimidated to speak up. FLOW Speak provides a safe, low-pressure space for shy students to practice speaking and build confidence. As they see their feedback scores improve over time, you'll notice them gradually finding their voice and speaking up more in your class.
      </p>


      {/* ------------------------ */}


      <h3>Teacher Benefits</h3>
      <p>
        <strong>Have a competitive offering</strong>
        <br />
        Adding FLOW Speak to your learning package can make your entire offering more competitive and provide more value to your students. Having an after class component that focuses on speaking is not something most teachers can provide.
      </p>

      <p>
        <strong>Retain students longer</strong>
        <br />
        Having a complete package that allows students to learn in-class and speak after class can lead to longer retention since students will see more progress.
      </p>

      <p>
        <strong>Additional revenue</strong>
        <br />
        If you would like, you can purchase Premium seats for your students via the FLOW Admin. You can then upsell those seats to your students as part of your learning package.
      </p>
      <p>
        Note - you can also tell your students to purchase FLOW Speak on their own if you do not want to make the purchase yourself.
      </p>


      {/* ------------------------ */}
      <p>&nbsp;</p>

      <h2>How to include FLOW Speak in your package</h2>

      <p>
        <strong>Step 1</strong>
        <br />
        List FLOW Speak as a benefit in your learning package. You can use wording like:
      </p>
      <p style={{marginLeft: '15px'}}>
        FLOW Speak is an AI-powered English-speaking practice app that provides unlimited practice and instant feedback. Its lessons focus on real-world situations for daily life, the classroom, the workplace, and more. With FLOW Speak, you get the extra practice you need to improve faster and gain confidence in speaking.
      </p>

      <p>
        <strong>Step 2</strong>
        <br />
        When a student(s) buys your learning package, you can purchase a seat and send them an invite to FLOW Speak.
      </p>
      <p>
        To buy the Premium seat:
      </p>

      <ul>
        <li>Login to the FLOW Admin and go to the <Link to='/seats'>Seats</Link> tab via the top nav</li>
        <li>Find the “Buy Seats” section on the page</li>
        <li>Select the how long you want the student to have access to FLOW Speak</li>
        <li>Select how many seats to purchase</li>
        <li>Click the “Next” button and enter your credit card information to make the purchase</li>
        <li>When you return to the Seats screen, you will see the available Seat in the “Seats” section of the page</li>
      </ul>

      <p>
        To invite the student to FLOW Speak:
      </p>

      <ul>
        <li>Login to the FLOW Admin and go to the Groups tab</li>
        <li>Click into the Group you want this student to be a part of</li>
        <li>Scroll down to the “Invite Learners” section</li>
        <li>Enter the student's email address and choose the Plan ( choose the Premium option you purchased )</li>
        <li>Click “Send Invite”</li>
        <li>The student will receive an email with instructions on how to activate their FLOW Speak account. This email will include your Organization's name.</li>
      </ul>


      <p>
        <strong>Step 3</strong>
        <br />
        During the first class, ensure the student(s) has activated their FLOW Speak account and is logged in. Do one or two lessons with them to make sure they understand how to use the application.
      </p>
      <p>
        At the end of each class, either assign specific homework ( for example: do 5 lessons before the next class ) or mention FLOW Speak and remind the student(s) to practice their speaking before the next class.
      </p>


      <p>
        <strong>Step 4</strong>
        <br />
        Monitor students' progress and compliment their activity.
      </p>
      <p>
        To see students progress, login to the FLOW Admin and click into the Group the student(s) is in. Find them in the Learners list. From here, you will see a snapshot of their activity. Click the eye icon to see details on the lessons they have completed. Additionally, you can listen to the recordings from each learners' lessons and later give feedback in class.
      </p>

      {/* ------------------------ */}

      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        If you have any questions or want to chat, reach out to our support team at <a href='support@flowspeak.io'>support@flowspeak.io</a> or on WhatsApp at +1 888 212-6008.
      </p>

    </div>
  );
}

export default ResourceAddFlowToPackage;