import axios from "axios";
import { getToken } from "./tokenHelpers";

let apiBase;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  apiBase = "http://localhost:3001/api/";
} else {

  const host = window.location.host;
  const onStaging = host.search('--flow-speak-admin.netlify.app') > 0;

  if( onStaging ) {
    apiBase = "https://flow-server-staging-419687e67428.herokuapp.com/api/"; // Staging URL
  } else {
    apiBase = "https://admin.newvoicelearning.com/api/";
  }

}

export const apiBaseUrl = apiBase;


axios.defaults.baseURL = apiBaseUrl;





const setAuthHeader = (token) => ({
  headers: { authorization: `Bearer ${token}` }
});

const setAuthHeaderForm = (token) => ({
  headers: { authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }
});

export async function authenticatedGet(route) {
  const token = await getToken();
  const data = await axios.get(route, setAuthHeader(token));
  return data;
}

export async function authenticatedPost(route, payload) {
  const token = await getToken();
  const data = await axios.post(route, payload, setAuthHeader(token));
  return data;
}

export async function authenticatedPostForm(route, payload) {
  const token = await getToken();
  const data = await axios.post(route, payload, setAuthHeaderForm(token));
  return data;
}


// Define the API calls
export const signin = (payload) => axios.post(`/users/login`, payload);
export const setPassword = (payload) => axios.post(`/users/set_password`, payload);
export const register = (payload) => axios.post(`/users/`, payload);

export const forgotPassword = (payload) => axios.post(`/users/send_password_reset_email`, payload);
export const resetPassword = payload => axios.post(`/users/reset_password`, payload);


export const getOngoardingProfile = () => authenticatedGet(`/org_admin/onboarding_profile`);
export const onboardingUpdate = (payload) => authenticatedPost(`/org_admin/onboarding_update`, payload);
export const createCheckoutSession = (payload) => authenticatedPost(`/org_admin/create_checkout_session`, payload);
export const verifyCheckoutSession = (payload) => authenticatedPost(`/org_admin/verify_checkout_session`, payload);
export const getTransactions = (payload) => authenticatedPost(`/org_admin/transactions`, payload);

export const getProfile = (payload) => authenticatedPost(`/org_admin/profile`, payload);
export const getGroup = (payload) => authenticatedGet(`/org_admin/groups/${payload.id}`);
export const getLearner = (payload) => authenticatedGet(`/org_admin/learners/${payload.id}`);
export const getLearnerResponses = (payload) => authenticatedGet(`/org_admin/learners/${payload.id}/responses/${payload.exerciseId}`);
export const editLearnerPassword = (payload) => authenticatedPost(`/org_admin/learners/${payload.id}/edit_password`, payload);
export const removeLearner = (payload) => authenticatedPost(`/org_admin/learners/${payload.id}/remove`, payload);
export const sendPasswordResetEmail = (payload) => authenticatedPost(`/org_admin/learners/${payload.id}/send_password_reset_email`, payload);

export const getNewGroupData = (payload) => authenticatedGet(`/org_admin/${payload.organizationId}/new_group_data`);
export const createGroup = (payload) => authenticatedPost(`/org_admin/groups/new`, payload);

export const getGroupData = (payload) => authenticatedGet(`/org_admin/groups/${payload.id}/group_data`);
export const updateGroup = (payload) => authenticatedPost(`/org_admin/groups/${payload.id}/update`, payload);

export const createInvite = (payload) => authenticatedPost(`/org_admin/groups/${payload.id}/create_invite`, payload);
export const resendInvite = (payload) => authenticatedPost(`/org_admin/groups/${payload.id}/resend_invite_email`, payload);
export const removeInvite = (payload) => authenticatedPost(`/org_admin/groups/${payload.id}/remove_invite_email`, payload);

export const getStaff = (payload) => authenticatedGet(`/org_admin/${payload.organizationId}/staff`);
export const createStaff = (payload) => authenticatedPost(`/org_admin/${payload.organization_id}/staff/new`, payload);
export const getStaffInfo = (payload) => authenticatedGet(`/org_admin/staff/${payload.id}`);
export const updateStaff = (payload) => authenticatedPost(`/org_admin/staff/update`, payload);

export const getSeats = (payload) => authenticatedGet(`/org_admin/${payload.organizationId}/seats`);

export const searchAssessments = (payload) => authenticatedPost(`/org_admin/assessments`, payload);
export const getAssessment = (payload) => authenticatedGet(`/org_admin/assessments/${payload.id}`);

export const searchCodes = (payload) => authenticatedPost(`/org_admin/assessment_codes`, payload);
export const getCode = (payload) => authenticatedGet(`/org_admin/assessment_codes/${payload.id}`);

export const getCourses = () => authenticatedGet('/courses');
export const getCoursesDetailed = (payload) => authenticatedGet(`/courses_detailed/${payload.organizationId}`);
export const getPathwaysDetailed = () => authenticatedGet('/pathways_detailed');