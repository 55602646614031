import { Link } from "react-router-dom";


function ResourceQuickstartClass() {

  return (
    <div class='body-container resource'>
      <h2>Quick Start: Using FLOW With Your Class</h2>

      <p>
        Congratulations, you set up your FLOW Administrator account! Follow this Quick Start guide to invite your students and set them up for speaking success.
      </p>


      <h3>Step 1: Buy seats ( optional )</h3>
      <p>
        You can purchase Premium seats for your students to include FLOW Speak as part of your learning package. Premium seats will give your students full access to all Pathways, Courses, our 1,200+ lesson Library, and advanced AI speech scoring.
      </p>
      <p>
        In the FLOW Administrator, go to the <Link to='/seats'>Seats</Link> screen via the top nav. Choose the duration and number of seats you need ( don't worry, you can purchase more later, so don't feel pressured to buy more than you need ). After you complete your purchase, you'll see the number of available seats in the top section of the screen.
      </p>
      <p>
        If you want your students to purchase Premium accounts on their own, you skip this part and later assign them first to free Community tier accounts. The Community tier offers 2 lessons per week and basic AI feedback.
      </p>


      <h3>Step 2: Invite your Learners</h3>
      <p>
        In the FLOW Admin, click into the Group you want to work with. You likely just have one Group when you start. Over time, you can add more Groups for different classes you have.
      </p>
      <p>
        Scroll down to the “Invite Learners” section. Enter each student's email and choose the Plan you want them to have. If you purchased Premium seats, choose those from the drop down list.
      </p>
      <p>
        When you click “Send Invite”, the student will receive an email inviting them to activate their FLOW Speak account. The email will mention your Organization name.
      </p>


      <h3>Step 3: Prepare your lesson plans</h3>
      <p>
        If possible, try to work the FLOW Speak curriculum into your lesson plans. If you aren't able to do this, no problem - simply assign FLOW Speak as homework.
      </p>
      <p>
        You can review the curriculum here: <a href='/resources/pathways' target='_blank'>Pathways List</a> and <a href='/resources/courses' target='_blank'>Courses List</a>.
      </p>
      <p>
        <i>Reminder - When you created or edited your Group, you chose a curriculum to use. You can see the chosen curriculum by viewing the Group and expanding the Details section.</i>
      </p>


      <h3>Step 4: Ensure students are ready to go</h3>
      <p>
        When you first start using FLOW Speak with your class, spend some in-class time to ensure everyone has their FLOW Speak account set up and understands how to use it. Consider taking some time to have each student do the first lesson or two in class so you can answer any questions they have and get them excited to keep using FLOW Speak outside of class.
      </p>
      <p>
        Some students may prefer to use the mobile app instead of the web based application. After they have activated their account on the web, you can help them download the mobile app. Students can login to the mobile app with the same email and password they set when they activated their account.
      </p>
      <p>
        To find the mobile app, students can go to the Apple App Store or the Google Play Store and search for “FLOW Speak”.
      </p>


      <br />
      <hr/>
      <br />
      <br />

      <h2>FAQ</h2>

      <h3>What are Pathways?</h3>
      <p>
        Pathways are structured learning tracks that guide learners through the learning journey. There are three Pathways ( Beginner, Intermediate, and Advanced ) and each have three levels that last 8 weeks. Each level has five lessons each week, for a total of forty lessons. <a href='/resources/pathways' target='_blank'>View the Pathways</a>.
      </p>
      <p>
        You can read more about Pathways here: <a href="https://www.flowspeak.io/articles/pathways-level-up-your-speaking" target='_blank'>https://www.flowspeak.io/articles/pathways-level-up-your-speaking</a>.
      </p>

      <h3>What are Courses?</h3>
      <p>
        Courses are arranged around a specific topic ( such as casual speaking, shopping, business english, preparing for the workplace, etc. ) and last from 4 weeks to 16 weeks, depending on the course. <a href='/resources/courses' target='_blank'>View the full list of Courses</a>.
      </p>

      <h3>What's the difference between Premium & Community Accounts</h3>
      <p>
        Premium accounts give Learners full access to all Pathways, Courses, and our 1,200+ lesson Library, as well as advanced AI speech scoring.
      </p>
      <p>
        Community accounts are free and give Learners limited access to Pathways and beginner Courses, as well as basic AI speech scoring.
      </p>

      <h3>I have questions, how can I reach FLOW?</h3>
      <p>
        If you have any questions or want to chat, reach out to our support team at <a href='mailto:support@flowspeak.io'>support@flowspeak.io</a> or on WhatsApp at +1 888 212-6008.
      </p>

    </div>
  );
}

export default ResourceQuickstartClass;