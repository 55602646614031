import { Link } from 'react-router-dom';


function ResourceList(props) {

  // Define variables
  const profileData = props.profileData;
  const organizationType = profileData.organizationType;


  // Screen components
  let quickStartClassElement;
  let quickStartTeamElement;
  let guideAddFlowPackageElement;

  if( organizationType != 'Business' ) {
    quickStartClassElement = (
      <p style={{marginTop: '15px'}}>
        <Link to='/resources/quick-start-using-flow-with-your-class'>Quick Start: Using FLOW With Your Class</Link>
      </p>
    );
  }

  if( organizationType === 'Business' || organizationType === 'Other' ) {
    quickStartTeamElement = (
      <p style={{marginTop: '15px'}}>
        <Link to='/resources/quick-start-using-flow-with-your-team'>Quick Start: Using FLOW With Your Team</Link>
      </p>
    );
  }

  if( organizationType === 'Independent teacher' ) {
    guideAddFlowPackageElement = (
      <p style={{marginTop: '15px'}}>
        <Link to='/resources/guide-add-flow-to-your-learning-package'>Guide: Adding FLOW Speak to Your Learning Package</Link>
      </p>
    );
  }


  return (
    <div style={{width: '100%', marginTop: '90px', marginBottom: '60px'}}>
      <h2>Resources</h2>
      <br />

      <p>
        <a href='https://files.flowspeak.io/files/resources/FLOW%20Administrator%20Manual.pdf' target='_blank'>FLOW Administrator Manual</a>
      </p>

      {quickStartClassElement}
      {quickStartTeamElement}
      {guideAddFlowPackageElement}

      <p style={{marginTop: '15px'}}>
        <Link to='/resources/courses'>Course List</Link>
      </p>

      <p style={{marginTop: '15px'}}>
        <Link to='/resources/pathways'>Pathway List</Link>
      </p>

    </div>
  );
}

export default ResourceList;