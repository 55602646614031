import { searchCodes } from "../utilities/auth/authAPI";
import PrimeTable from "../utilities/table/primeTable";


function AssessCodes(props) {

  // Define variables
  const organizationId = props.organizationId;
  const viewCode = props.viewCode;

  const viewBody = (item) => {

    const assessmentId = item.assessmentId;
    let style;

    if( !assessmentId ) {
      style = {display: 'none'};
    }

    return (
      <button
        onClick={() => viewCode(item)}
        class='icon icon-view-fill'
        title='View'
        style={style}
      />
    );
  }

  const viewLink = (item) => {

    const code = item.code;
    const status = item.status;

    let buttonElement;
    if( status != 'Complete' ) {

      const url = `https://app.flowspeak.io/assessment/${code}`;

      buttonElement = (
        <button
          onClick={() => copyLink(url)}
          class='button-invite-url'
          title='View'
        >Copy link</button>
      );
    }

    return (
      <>{buttonElement}</>
    )
  }

  const columns = [
    {name: 'code', display: 'Code', sortable: true},
    {name: 'status', display: 'Status', sortable: true},
    {name: 'link', display: 'Link', sortable: false, body: viewLink},
    {name: 'date', display: 'Last Update', sortable: true},
    {name: 'view', display: '', sortable: false, body: viewBody, bodyStyle: {textAlign: 'center'}}
  ];


  // Define functions
  function copyLink(link) {
    navigator.clipboard.writeText(link);
    alert('The Assessment Code link has been copied to your clipboard. You can send this link directly to a learner.');
  }


  return (
    <div style={{width: '100%'}}>

      <p style={{marginBottom: '45px'}}>
        To give an Assessment Code to a Learner, find a code with the status "Not Started" and copy the code. Then send the code to the Learner and direct them to <span style={{fontWeight: '600'}}>https://app.flowspeak.io/assessment</span> where then can enter the code and take their Assessment.
      </p>

      <PrimeTable
        columns={columns}
        organizationId={organizationId}
        apiMethod={searchCodes}
        searchPlaceholder={'Search by code'}
      />
    </div>
  );
}

export default AssessCodes;