import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { Toaster } from 'react-hot-toast';
import PrivateRoute from "./components/utilities/auth/privateRoute";

import LogoutScreen from './components/start/logout';
import Login from './components/start/login';
import SetPassword from './components/start/setPassword';
import RegisterScreen from './components/start/register';
import ForgotPasswordScreen from './components/start/forgotPassword';
import ResetPasswordScreen from './components/start/resetPassword';

import OnboardScreen from './components/start/onboarding/onboard';
import PurchaseCompleteScreen from './components/start/onboarding/purchaseComplete';

import GroupsScreen from './components/groups';
import Group from './components/groups/group';
import AddGroup from './components/groups/addGroup';
import EditGroup from './components/groups/editGroup';

import AssessmentsScreen from './components/assessments';

import StaffScreen from './components/staff';
import AddStaff from './components/staff/addStaff';

import SeatsScreen from './components/seats';
import SeatsPurchaseCompleteScreen from './components/seats/seatsPurchaseComplete';

import ResourceScreen from './components/resources';

import NotFound from './components/start/notFound';


function App() {
  return (
    <BrowserRouter>
    <Toaster />
      <RoutesF />
    </BrowserRouter>
  );
}

function RoutesF() {
  return (
    <Routes>

      <Route path="/logout" element={<LogoutScreen />} />

      <Route path="/login" element={<Login />} />
      <Route path="/set-password" element={<SetPassword />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
      <Route path="/reset-password" element={<ResetPasswordScreen />} />
      <Route path="/reset_password" element={<ResetPasswordScreen />} />

      <Route path="/onboard/purchase_complete/:sessionId" element={<PrivateRoute component={PurchaseCompleteScreen} />} />
      <Route path="/onboard" element={<PrivateRoute component={OnboardScreen} />} />

      <Route path="/" element={<PrivateRoute component={GroupsScreen} />} />
      <Route path="/groups/new" element={<PrivateRoute component={AddGroup} />} />
      <Route path="/groups/:id/edit" element={<PrivateRoute component={EditGroup} />} />
      <Route path="/groups/:id" element={<PrivateRoute component={Group} />} />

      <Route path="/assessments" element={<PrivateRoute component={AssessmentsScreen} />} />

      <Route path="/staff" element={<PrivateRoute component={StaffScreen} />} />
      <Route path="/staff/new" element={<PrivateRoute component={AddStaff} />} />

      <Route path="/seats" element={<PrivateRoute component={SeatsScreen} />} />
      <Route path="/seats/purchase_complete/:sessionId" element={<PrivateRoute component={SeatsPurchaseCompleteScreen} />} />

      <Route path="/resources/:document" element={<PrivateRoute component={ResourceScreen} />} />


      <Route path="*" element={<NotFound />} />

    </Routes>
  );

}

export default App;