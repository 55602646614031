import { Link } from "react-router-dom";


function ResourceQuickstartTeam() {

  return (
    <div class='body-container resource'>
      <h2>Quick Start: Using FLOW With Your Team</h2>

      <p>
        Congratulations, you set up your FLOW Administrator account! Follow this Quick Start guide to invite your team and set them up for speaking success.
      </p>


      <h3>Step 1: Buy seats ( optional )</h3>
      <p>
        You can purchase premium seats for your team so you can offer FLOW Speak as part of your learning and development plan. Premium seats will give your team full access to all Pathways, Courses, our 1,200+ lesson Library, and advanced AI speech scoring.
      </p>
      <p>
        In the FLOW Administrator, go to the <Link to='/seats'>Seats</Link> screen via the top nav. Choose the duration and number of seats you need ( don’t worry, you can purchase more later, so don’t feel pressured to buy more than you need ). After you complete your purchase, you’ll see the number of available seats in the top section of the screen.
      </p>
      <p>
        If you want your team to purchase Premium accounts on their own, you skip this part and later assign them first to free Community tier accounts. The Community tier offers 2 lessons per week and basic AI feedback.
      </p>


      <h3>Step 2: Invite your Team</h3>
      <p>
        In the FLOW Admin, click into the Group you want to work with. You likely just have one Group when you start. Over time, you can add more Groups for different teams, departments, or cohorts you have.
      </p>
      <p>
        Scroll down to the “Invite Learners” section. Enter each team member’s email and choose the Plan you want them to have. If you purchased Premium seats, choose those from the drop down list.
      </p>
      <p>
        When you click “Send Invite”, the team member will receive an email inviting them to activate their FLOW Speak account. The email will mention your Organization name.
      </p>


      <h3>Step 3: Ensure team members are ready to go</h3>
      <p>
      Encourage your team members to activate their accounts right away and get speaking! Once they get started, they are all set to go since FLOW Speak is built as a self-learning tool. You can recommend the following article with the top 7 tips for using FLOW Speak to build confidence:
      <a href='https://www.flowspeak.io/articles/top-7-tips-for-using-flow' target="_blank">https://www.flowspeak.io/articles/top-7-tips-for-using-flow</a>.
      </p>

      <br />
      <hr/>
      <br />
      <br />

      <h2>FAQ</h2>

      <h3>What are Pathways?</h3>
      <p>
        Pathways are structured learning tracks that guide learners through the learning journey. There are three Pathways ( Beginner, Intermediate, and Advanced ) and each have three levels that last 8 weeks. Each level has five lessons each week, for a total of forty lessons. <a href='/resources/pathways' target='_blank'>View the Pathways</a>.
      </p>
      <p>
        You can read more about Pathways here: <a href="https://www.flowspeak.io/articles/pathways-level-up-your-speaking" target='_blank'>https://www.flowspeak.io/articles/pathways-level-up-your-speaking</a>.
      </p>

      <h3>What are Courses?</h3>
      <p>
        Courses are arranged around a specific topic ( such as casual speaking, shopping, business english, preparing for the workplace, etc. ) and last from 4 weeks to 16 weeks, depending on the course. <a href='/resources/courses' target='_blank'>View the full list of Courses</a>.
      </p>

      <h3>What's the difference between Premium & Community Accounts</h3>
      <p>
        Premium accounts give Learners full access to all Pathways, Courses, and our 1,200+ lesson Library, as well as advanced AI speech scoring.
      </p>
      <p>
        Community accounts are free and give Learners limited access to Pathways and beginner Courses, as well as basic AI speech scoring.
      </p>

      <h3>I have questions, how can I reach FLOW?</h3>
      <p>
        If you have any questions or want to chat, reach out to our support team at <a href='mailto:support@flowspeak.io'>support@flowspeak.io</a> or on WhatsApp at +1 888 212-6008.
      </p>

    </div>
  );
}

export default ResourceQuickstartTeam;