import { useEffect } from "react";


function LogoutScreen() {

  // Component load actions
  useEffect(() => {
    localStorage.removeItem("jwt-token");
    window.location = '/login';
  }, []);


  return (<></>);
}

export default LogoutScreen;