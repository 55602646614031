import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import apiCall from "../../helperFunctions/apiCall";
import { getCoursesDetailed } from '../utilities/auth/authAPI';

import { Loading } from '../utilities/helpers';
import Course from './course';


function CoursesList() {

  // Define variables
  const organizationId = useSelector((state) => state.profile.organizationId);
  const [loading, setLoading] = useState(true);
  const [courseGroups, setCourseGroups] = useState([]);


  // Component load actions
  useEffect(() => {
    if( organizationId ) {
      apiCall(getCoursesDetailed,{organizationId: organizationId}).then((response) => {
        if( response.success ) {
          setCourseGroups(response.data);
          setLoading(null);
        }
      });
    }
  }, [organizationId]);


  // Screen components
  let loadingElement;
  if( loading ) {
    loadingElement = (
      <div style={{marginTop: '120px'}}>
        <Loading />
      </div>
    );
  }


  return (
    <div class='body-container resource'>
      <h2>Courses</h2>
      <p class='p-secondary' style={{marginTop: '-10px'}}><i>Courses are grouped by topic</i></p>

      {loadingElement}

      {courseGroups.map((group, index) =>
        <div key={`course-group-${index}`} style={{marginBottom: '60px'}}>
          <h3>Topic: {group.title}</h3>

          {group.courses.map((course, index) =>
            <Course course={course} key={`course-courses-${index}`} />
          )}
        </div>
      )}
    </div>
  );
}

export default CoursesList;