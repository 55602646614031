import PrimeTableSimple from "../utilities/table/primeTableSimple";


function CourseLessons(props) {

  // Define variables
  const lessons = props.lessons;

  const columns = [
    {name: 'weekNumber', display: 'Week'},
    {name: 'title', display: 'Title'},
    {name: 'lessonType', display: 'Type'},
    {name: 'level', display: 'Level'},
    {name: 'cefr', display: 'CEFR'},
    {name: 'gse', display: 'GSE'},
    {name: 'duration', display: 'Duration'},
  ];


  return (
    <PrimeTableSimple
      columns={columns}
      data={lessons}
      showExport={true}
    />
  );
}

export default CourseLessons;