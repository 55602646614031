import { useState, useEffect } from 'react';

import apiCall from '../../helperFunctions/apiCall';
import { getPathwaysDetailed } from '../utilities/auth/authAPI';

import { Loading } from '../utilities/helpers';
import Course from './course';


function PathwaysList() {

  // Define variables
  const [loading, setLoading] = useState(true);
  const [pathwayGroups, setPathwayGroups] = useState([]);


  // Component load actions
  useEffect(() => {
    apiCall(getPathwaysDetailed).then((response) => {
      if( response.success ) {
        setPathwayGroups(response.data);
        setLoading(null);
      }
    });
  }, []);


  // Screen components
  let loadingElement;
  if( loading ) {
    loadingElement = (
      <div style={{marginTop: '120px'}}>
        <Loading />
      </div>
    );
  }


  return (
    <div class='body-container resource'>
      <h2>Pathways</h2>

      {loadingElement}

      {pathwayGroups.map((group, index) =>
        <div key={`pathway-group-${index}`} style={{marginBottom: '60px'}}>
          <h3>{group.title}</h3>

          {group.blocks.map((block, index) =>
            <Course course={block} key={`pathway-courses-${index}`} />
          )}
        </div>
      )}
    </div>
  );
}

export default PathwaysList;